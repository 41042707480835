import {
  Button,
  Modal as ModalWindow,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

const Modal = ({ isOpen, toggle, title, body, onConfirm }) => {
  return (
    <ModalWindow isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>{body}</ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={onConfirm}>
          Confirm
        </Button>{' '}
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </ModalWindow>
  );
};
export default Modal;
