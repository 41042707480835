import 'bootstrap/dist/css/bootstrap.min.css';
import stationCalculator from '../utils/stationCalculator';

const CurrentStation = ({
  alignment,
  alignmentFirstStation,
  units,
  coords,
  isGeolocationAvailable,
  isGeolocationEnabled,
}) => {
  // Calculate current station
  let lineLength, offset, distFromStart, currentStation, offsetUnits, accuracy;

  if (coords && alignment) {
    [lineLength, offset, distFromStart] = stationCalculator(
      alignment,
      coords,
      units
    );

    accuracy = Math.round(coords.accuracy * 10) / 10;

    if (distFromStart === 0 || distFromStart === lineLength) {
      // Not within alignment
      currentStation = 'N/a';
      offset = 'N/a';
    } else {
      // firstStation = 123+456.78
      alignmentFirstStation = alignmentFirstStation.split('+');
      const firstStationLeft = Number(alignmentFirstStation[0]);
      // firstStationLeft = 123
      let firstStationRight = alignmentFirstStation[1].split('.');
      let firstStationDecimal = null;

      firstStationRight = firstStationRight[0];
      // firstStationRight = 456
      firstStationDecimal = Number(firstStationRight[1]);
      // firstStationDecimal = 78

      let currentStationLeft,
        currentStationRight,
        currentStationDecimal,
        rightLength;
      if (firstStationRight.length === 3) {
        // Metric station number
        rightLength = 3;
        offsetUnits = 'm';
        distFromStart = distFromStart / 1000; // to km

        currentStationLeft = firstStationLeft + Math.trunc(distFromStart); // add number of whole kms

        const currentStationMeters = (distFromStart % 1) * 1000;

        currentStationRight =
          Number(firstStationRight) + Math.trunc(currentStationMeters); // add additional meters

        if (firstStationDecimal) {
          currentStationDecimal =
            firstStationDecimal + Math.trunc((currentStationMeters % 1) * 100); // add fractional meters
        } else {
          currentStationDecimal = Math.trunc((currentStationMeters % 1) * 100);
        }
      } else {
        // Imperial station number
        rightLength = 2;
        offsetUnits = 'ft';
        accuracy = accuracy * 3.28084; // to feet
        distFromStart = distFromStart / 100; // to stations

        currentStationLeft = firstStationLeft + Math.trunc(distFromStart);

        const currentStationFeet = (distFromStart % 1) * 100;

        currentStationRight =
          Number(firstStationRight) + Math.trunc(currentStationFeet); // add aditional feet

        if (firstStationDecimal) {
          currentStationDecimal =
            firstStationDecimal + Math.trunc((currentStationFeet % 1) * 100); // add fractional feet
        } else {
          currentStationDecimal = Math.trunc((currentStationFeet % 1) * 100);
        }
      }

      offset = Math.round(offset * 10) / 10;

      currentStation =
        currentStationLeft.toString() +
        '+' +
        currentStationRight.toString().padStart(rightLength, '0') +
        '.' +
        currentStationDecimal.toString();
    }
  }

  return !isGeolocationAvailable ? (
    <div>Your browser does not support Geolocation</div>
  ) : !isGeolocationEnabled ? (
    <div>Geolocation is not available</div>
  ) : !alignment ? (
    <div>
      No alignment file. Upload one to your Project Settings on Qualomate Desktop.
    </div>
  ) : distFromStart ? (
    <p>
      Current Station: {currentStation} Offset: {offset}
      {offsetUnits} {'\n'}
      Accuracy: &#177;{accuracy}
      {offsetUnits}
    </p>
  ) : (
    <div>Getting the location data&hellip; </div>
  );
};

export default CurrentStation;
