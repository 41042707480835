import { useEffect, useState } from 'react';
import {
  NavLink as RouterNavLink,
  useLocation,
  matchPath,
} from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import logo from '../assets/Main_Colour_ForLightBG.svg';

import {
  Collapse,
  Container,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import './NavBar.css';

const NavBar = ({ free_tier }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [projectId, setProjectId] = useState('');

  const location = useLocation();

  const { user, isAuthenticated, loginWithRedirect, logout } = useAuth0();

  let homePage = '/';

  if (isAuthenticated) {
    homePage = '/select-project';
  }

  useEffect(() => {
    const storedProjectId = localStorage.getItem('projectId');
    setProjectId(storedProjectId);
  });

  const logoutWithRedirect = () =>
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });

  const handleNavLinkClick = () => {
    if (window.innerWidth < 768) {
      setIsOpen(!isOpen);
    }
  };

  return (
    <div className="nav-container">
      <Navbar color="light" light expand="md" container={false} id="navbar">
        <Container>
          <NavbarBrand
            tag={RouterNavLink}
            to={homePage}
            onClick={() => setIsOpen(false)}
          >
            <img alt="Qualomate Logo" src={logo} className="nav-logo" />
          </NavbarBrand>
          <NavbarToggler onClick={() => setIsOpen(!isOpen)} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="mr-auto" navbar>
              {!isAuthenticated && (
                <NavItem>
                  <NavLink
                    tag={RouterNavLink}
                    to="/"
                    exact
                    activeClassName="router-link-exact-active"
                    onClick={handleNavLinkClick}
                  >
                    Home
                  </NavLink>
                </NavItem>
              )}
              {isAuthenticated && (
                <NavItem>
                  <NavLink
                    tag={RouterNavLink}
                    to="/select-project"
                    exact
                    activeClassName="router-link-exact-active"
                    onClick={handleNavLinkClick}
                  >
                    Select Project
                  </NavLink>
                </NavItem>
              )}
              {isAuthenticated && projectId && (
                <NavItem>
                  <NavLink
                    tag={RouterNavLink}
                    to={`/reports/${projectId}`}
                    exact
                    activeClassName="router-link-exact-active"
                    onClick={handleNavLinkClick}
                  >
                    Reports
                  </NavLink>
                </NavItem>
              )}
              {isAuthenticated && projectId && !free_tier && (
                <NavItem>
                  <NavLink
                    tag={RouterNavLink}
                    to={`/incidents/${projectId}`}
                    exact
                    activeClassName="router-link-exact-active"
                    onClick={handleNavLinkClick}
                  >
                    Incidents
                  </NavLink>
                </NavItem>
              )}
              {/* {isAuthenticated && projectId && !free_tier && (
                <NavItem>
                  <NavLink
                    tag={RouterNavLink}
                    to={`/overview/${projectId}`}
                    exact
                    activeClassName="router-link-exact-active"
                    onClick={handleNavLinkClick}
                  >
                    Overview
                  </NavLink>
                </NavItem>
              )} */}
            </Nav>
            <Nav className="d-none d-md-block" navbar>
              {!isAuthenticated && (
                <NavItem>
                  <Button
                    id="qsLoginBtn"
                    className="btn-margin"
                    onClick={() => loginWithRedirect()}
                  >
                    Log in
                  </Button>
                </NavItem>
              )}
              {isAuthenticated && (
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret id="profileDropDown">
                    <img
                      src={user.picture}
                      alt="Profile"
                      className="nav-user-profile rounded-circle"
                      width="50"
                    />
                  </DropdownToggle>
                  <DropdownMenu style={{ right: 0, left: 'auto' }}>
                    <DropdownItem header>{user.name}</DropdownItem>
                    <DropdownItem
                      id="qsLogoutBtn"
                      onClick={() => logoutWithRedirect()}
                    >
                      <FontAwesomeIcon icon="power-off" className="mr-3" /> Log
                      out
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
            </Nav>
            {!isAuthenticated && (
              <Nav className="d-md-none" navbar>
                <NavItem>
                  <Button
                    id="qsLoginBtn"
                    color="primary"
                    block
                    onClick={() => loginWithRedirect({})}
                  >
                    Log in
                  </Button>
                </NavItem>
              </Nav>
            )}
            {isAuthenticated && (
              <Nav
                className="d-md-none justify-content-between"
                navbar
                id="navbar-profile"
              >
                <NavItem>
                  <span className="user-info">
                    <img
                      src={user.picture}
                      alt="Profile"
                      className="nav-user-profile d-inline-block rounded-circle mr-3"
                      width="50"
                    />
                    <h6 className="d-inline-block">{user.name}</h6>
                  </span>
                </NavItem>
                <NavItem>
                  <FontAwesomeIcon icon="power-off" className="mr-3" />
                  <RouterNavLink
                    to="#"
                    id="qsLogoutBtn"
                    onClick={() => logoutWithRedirect()}
                  >
                    Log out
                  </RouterNavLink>
                </NavItem>
              </Nav>
            )}
          </Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default NavBar;
