import { useEffect, useState } from 'react';
import { Alert } from 'reactstrap';

import ReportTemplate from '../components/ReportTemplate';
import Loading from '../components/Loading';

import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { getConfig } from '../config';

export const ExternalApiComponent = ({ free_tier }) => {
  const { apiOrigin = 'https://app.qualomate.com', audience } = getConfig();
  // const { apiOrigin = 'http://127.0.0.1:8050', audience } = getConfig();

  const { getAccessTokenSilently, loginWithPopup, getAccessTokenWithPopup } =
    useAuth0();

  const [state, setState] = useState({
    showResult: false,
    apiMessage: '',
    error: null,
  });

  const getTemplates = async () => {
    try {
      const token = await getAccessTokenSilently();

      const response = await fetch(`${apiOrigin}/api/report-templates`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          project_id: localStorage.getItem('projectId'),
          freeTier: free_tier,
        },
      });

      const responseData = await response.json();

      setState({
        ...state,
        showResult: true,
        apiMessage: responseData.body,
      });
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      });
    }
  };

  useEffect(() => {
    getTemplates();
    return;
  }, []);

  const handleConsent = async () => {
    try {
      await getAccessTokenWithPopup();
      setState({
        ...state,
        error: null,
      });
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      });
    }

    await getTemplates();
  };

  const handleLoginAgain = async () => {
    try {
      await loginWithPopup();
      setState({
        ...state,
        error: null,
      });
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      });
    }

    await getTemplates();
  };

  const handle = (e, fn) => {
    e.preventDefault();
    fn();
  };

  return (
    <>
      <div className="mb-5">
        {state.error === 'consent_required' && (
          <Alert color="warning">
            You need to{' '}
            <a
              href="#/"
              className="alert-link"
              onClick={(e) => handle(e, handleConsent)}
            >
              consent to get access to users api
            </a>
          </Alert>
        )}

        {state.error === 'login_required' && (
          <Alert color="warning">
            You need to{' '}
            <a
              href="#/"
              className="alert-link"
              onClick={(e) => handle(e, handleLoginAgain)}
            >
              log in again
            </a>
          </Alert>
        )}
      </div>

      <div className="result-block-container px-3">
        {state.showResult && (
          <div className="result-block" data-testid="api-result">
            <ReportTemplate jsonData={state.apiMessage} free_tier={free_tier} />
          </div>
        )}
      </div>
    </>
  );
};

export default withAuthenticationRequired(ExternalApiComponent, {
  onRedirecting: () => <Loading />,
});
