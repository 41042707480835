import { useLocation } from 'react-router-dom';
import logo from '../assets/Icon_Green.png';

const currentYear = new Date().getFullYear();
const qualomateLink = (
  <a
    href="https://www.qualomate.com"
    style={{ color: '#00ad8e', textDecoration: 'none' }}
  >
    Qualomate
  </a>
);

const Footer = () => {
  const { pathname } = useLocation();
  const reportsPath = '/reports/';

  if (pathname.startsWith(reportsPath)) return null;

  return (
    <footer className="bg-light p-2 text-center fixed-bottom">
      <img
        className="mb-1 app-logo"
        src={logo}
        alt="Qualomate logo"
        width="40"
      />
      <p className="mb-1">
        Copyright &#169; {currentYear} {qualomateLink}
      </p>
      <p className="mb-1">All Rights Reserved</p>
    </footer>
  );
};

export default Footer;
