import { useState, useEffect } from 'react';
import {
  FormGroup,
  FormFeedback,
  Label,
  Input,
  Button,
  Alert,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import { TagsInput } from 'react-tag-input-component';
import Select from 'react-select';

import { getConfig } from '../config';
import { useAuth0 } from '@auth0/auth0-react';

import './IncidentsForm.css';

const IncidentsForm = () => {
  const { apiOrigin = 'https://app.qualomate.com' } = getConfig();

  const { user, getAccessTokenSilently } = useAuth0();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const [projectData, setProjectData] = useState(null);

  const [selectedSubPackage, setSelectedSubpackage] = useState('');
  const [selectedLayer, setSelectedLayer] = useState('');
  const [itpItem, setItpItem] = useState('');
  const [selectedLots, setSelectedLots] = useState([]);

  const [eventDate, setEventDate] = useState('');
  const [title, setTitle] = useState('');
  const [incidentType, setIncidentType] = useState('');
  const [assignedTo, setAssignedTo] = useState([]);
  const [responseRequiredBy, setResponseRequiredBy] = useState('');

  const [issueDescription, setIssueDescription] = useState('');
  const [issueSeverity, setIssueSeverity] = useState('medium');
  const [delay, setDelay] = useState('');
  const [cost, setCost] = useState('');
  const [incidentEvidence, setIncidentEvidence] = useState([]);

  const [correctiveActionType, setCorrectiveActionType] = useState('');
  const [correctiveActionDescription, setCorrectiveActionDescription] =
    useState('');
  const [correctiveActionDate, setCorrectiveActionDate] = useState('');
  const [correctiveActionEvidence, setCorrectiveActionEvidence] = useState([]);
  const [status, setStatus] = useState('draft');

  const [titleValid, setTitleValid] = useState(true);
  const [eventDateValid, setEventDateValid] = useState(true);
  const [subpackageValid, setSubpackageValid] = useState(true);
  const [layerValid, setLayerValid] = useState(true);
  const [lotsValid, setLotsValid] = useState(true);

  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = await getAccessTokenSilently();

        const response = await fetch(`${apiOrigin}/api/incident-data`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            project_id: localStorage.getItem('projectId'),
          },
        });

        if (!response.ok) {
          setError(true);
          console.error('Error:', response.statusText);
          return;
        }

        const data = await response.json();
        setProjectData(data.body);
      } catch (error) {
        setError(true);
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    let timeoutId;
    if (submitted) {
      timeoutId = setTimeout(() => {
        setSubmitted(false);
      }, 3000);
    }
    return () => clearTimeout(timeoutId);
  }, [submitted]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setEventDateValid(!!eventDate);
    setTitleValid(!!title);
    setSubpackageValid(!!selectedSubPackage);
    setLayerValid(!!selectedLayer);
    setLotsValid(selectedLots.length > 0);

    if (
      !title ||
      !eventDate ||
      !selectedSubPackage ||
      !selectedLayer ||
      selectedLots.length === 0
    ) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      return;
    }

    try {
      setIsLoading(true);

      const token = await getAccessTokenSilently();

      const response = await fetch(`${apiOrigin}/api/incident-data`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          project_id: localStorage.getItem('projectId'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          currentUser: user.email,
          selectedSubPackage,
          itpItem,
          selectedLots,
          eventDate,
          title,
          incidentType,
          assignedTo,
          responseRequiredBy,
          issueDescription,
          issueSeverity,
          delay,
          cost,
          incidentEvidence,
          correctiveActionType,
          correctiveActionDescription,
          correctiveActionDate,
          correctiveActionEvidence,
          status,
        }),
      });

      if (!response.ok) {
        setError(true);
        console.error('Error:', response.statusText);
        return;
      }

      setSubmitted(true);
      resetForm();
    } catch (error) {
      setError(true);
      console.error('Error submitting form:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e, setter, validator) => {
    const { value } = e.target;
    setter(value);
    validator(value !== '');
  };

  const handleBlur = (value, validator) => {
    validator(value !== '');
  };

  const handleSubpackageChange = (e) => {
    setSelectedSubpackage(e.target.value);
    setSelectedLayer('');
    setItpItem('');
    setSelectedLots([]);
    setSubpackageValid(e.target.value !== '');
  };

  const handleLayerChange = (e) => {
    if (e.target.value === '') {
      setItpItem('');
    }
    setSelectedLayer(e.target.value);
    setSelectedLots([]);
    setLayerValid(e.target.value !== '');
  };

  const handleLotsChange = (lot) => {
    setSelectedLots(lot);
    setLotsValid(lot.length > 0);
  };

  // Set itpItem based on the selected layer
  useEffect(() => {
    if (selectedSubPackage && selectedLayer) {
      const selectedLayerItpItem =
        projectData[selectedSubPackage][selectedLayer].itp_item;
      setItpItem(selectedLayerItpItem);
    }
  }, [projectData, selectedLayer, selectedSubPackage]);

  // Clear file names in the file input fields
  const resetFileInput = () => {
    const incidentEvidenceFileInput =
      document.getElementById('incidentEvidence');
    if (incidentEvidenceFileInput) {
      incidentEvidenceFileInput.value = null;
    }
    const correctiveActionEvidenceFileInput = document.getElementById(
      'correctiveActionEvidence'
    );
    if (correctiveActionEvidenceFileInput) {
      correctiveActionEvidenceFileInput.value = null;
    }
  };

  const resetForm = () => {
    setSelectedSubpackage('');
    setSelectedLayer('');
    setItpItem('');
    setSelectedLots('');

    setEventDate('');
    setTitle('');
    setIncidentType('');
    setAssignedTo([]);
    setResponseRequiredBy('');

    setIssueDescription('');
    setIssueSeverity('medium');
    setDelay('');
    setCost('');
    setIncidentEvidence([]);

    setCorrectiveActionType('');
    setCorrectiveActionDescription('');
    setCorrectiveActionDate('');
    setCorrectiveActionEvidence([]);
    setStatus('draft');

    resetFileInput();

    setError(false);
  };

  if (error)
    return (
      <Alert className="m-4" color="danger">
        <h4 className="alert-heading">Oops!</h4>
        <p>There was an error loading the page. Please try again later.</p>
      </Alert>
    );

  return (
    <div className="incident-form-container">
      <h2 className="incident-form-title">Incident Form</h2>
      <form className="incident-form-body" onSubmit={handleSubmit}>
        {/* Subpackage */}
        <FormGroup>
          <Label for="subpackage">Subpackage*</Label>
          <Input
            className="form-select"
            type="select"
            name="subpackage"
            id="subpackage"
            value={selectedSubPackage}
            onChange={handleSubpackageChange}
            onBlur={() => setSubpackageValid(!!selectedSubPackage)}
            invalid={!subpackageValid}
          >
            <option value="">Select Subpackage</option>
            {projectData &&
              Object.keys(projectData).map((subPackage) => (
                <option key={subPackage} value={subPackage}>
                  {subPackage}
                </option>
              ))}
          </Input>
          {!subpackageValid && (
            <FormFeedback>Subpackage is required.</FormFeedback>
          )}
        </FormGroup>

        {/* Layer Name */}
        <FormGroup>
          <Label for="layerName">Layer Name*</Label>
          <Input
            type="select"
            name="layerName"
            id="layerName"
            value={selectedLayer}
            onChange={handleLayerChange}
            onBlur={() => setLayerValid(!!selectedLayer)}
            disabled={!selectedSubPackage}
            invalid={!layerValid}
          >
            <option value="">Select Layer</option>
            {selectedSubPackage &&
              Object.keys(projectData[selectedSubPackage]).map((layer) => (
                <option key={layer} value={layer}>
                  {layer}
                </option>
              ))}
          </Input>
          {!layerValid && <FormFeedback>Layer is required.</FormFeedback>}
        </FormGroup>

        {/* Lot Names */}
        <FormGroup>
          <Label for="lotNames">Lot Names*</Label>
          <Select
            value={selectedLots}
            isMulti={true}
            isDisabled={!selectedLayer}
            placeholder="Select Lots"
            options={
              selectedLayer
                ? Object.values(
                    projectData[selectedSubPackage][selectedLayer].lot_names
                  ).map((lot) => ({ value: lot, label: lot }))
                : null
            }
            onChange={(lot) => {
              handleLotsChange(lot);
            }}
            onBlur={() => setLotsValid(selectedLots.length > 0)}
            className={!lotsValid ? 'is-invalid' : ''}
          />
          {!lotsValid && (
            <div className="invalid-feedback">Minimum 1 lot is required.</div>
          )}
        </FormGroup>

        {/* Event Date */}
        <FormGroup>
          <Label for="title">Event Date*</Label>
          <Input
            type="date"
            name="eventDate"
            id="eventDate"
            value={eventDate}
            onChange={(e) =>
              handleInputChange(e, setEventDate, setEventDateValid)
            }
            onBlur={() => handleBlur(eventDate, setEventDateValid)}
            invalid={!eventDateValid}
          />
          {!eventDateValid && (
            <FormFeedback>Event Date is required.</FormFeedback>
          )}
        </FormGroup>

        {/* Title */}
        <FormGroup>
          <Label for="title">Title*</Label>
          <Input
            type="text"
            name="title"
            id="title"
            value={title}
            onChange={(e) => handleInputChange(e, setTitle, setTitleValid)}
            onBlur={() => handleBlur(title, setTitleValid)}
            invalid={!titleValid}
          />
          {!titleValid && <FormFeedback>Title is required.</FormFeedback>}
        </FormGroup>

        {/* Incident Type */}
        <FormGroup>
          <Label for="incidentType">Incident Type</Label>
          <Input
            type="select"
            name="incidentType"
            id="incidentType"
            value={incidentType}
            onChange={(e) => setIncidentType(e.target.value)}
          >
            <option value="">Select Incident Type</option>
            <option value="observation">Observation</option>
            <option value="opportunity_for_improvement">
              Opportunity for Improvement
            </option>
            <option value="punch_list_item">Punch List Item</option>
            <option value="deficiency">Deficiency</option>
            <option value="rework">Rework</option>
            <option value="non_conformance">Non-Conformance</option>
          </Input>
        </FormGroup>

        {/* Assigned To */}
        <FormGroup>
          <Label for="assignedTo">Assigned To</Label>
          <TagsInput
            name="assignedTo"
            id="assignedTo"
            value={assignedTo}
            onChange={setAssignedTo}
            placeHolder="Enter emails to assign"
          />
        </FormGroup>

        {/* Response Required By */}
        <FormGroup>
          <Label for="responseRequiredBy">Response Required By</Label>
          <Input
            type="date"
            name="responseRequiredBy"
            id="responseRequiredBy"
            value={responseRequiredBy}
            onChange={(e) => setResponseRequiredBy(e.target.value)}
          />
        </FormGroup>

        <div className="form-section-separator-container">
          <div className="form-section-separator" />
          <h5 className="form-section-separator-title">Issue</h5>
          <div className="form-section-separator" />
        </div>

        {/* Issue Description */}
        <FormGroup>
          <Label for="issueDescription">Issue Description</Label>
          <Input
            type="textarea"
            name="issueDescription"
            id="issueDescription"
            value={issueDescription}
            onChange={(e) => setIssueDescription(e.target.value)}
          />
        </FormGroup>

        {/* Issue Severity */}
        <FormGroup>
          <Label for="issueSeverity">Issue Severity</Label>
          <Input
            type="select"
            name="issueSeverity"
            id="issueSeverity"
            value={issueSeverity}
            onChange={(e) => setIssueSeverity(e.target.value)}
          >
            <option value="low">Low</option>
            <option value="medium">Medium</option>
            <option value="high">High</option>
          </Input>
        </FormGroup>

        {/* Delay */}
        <FormGroup>
          <Label for="delay">Delay</Label>
          <div className="input-group">
            <Input
              type="number"
              name="delay"
              id="delay"
              value={delay}
              onChange={(e) => setDelay(e.target.value)}
            />
            <div className="input-group-append">
              <span className="input-group-text">days</span>
            </div>
          </div>
        </FormGroup>

        {/* Cost */}
        <FormGroup>
          <Label for="cost">Cost</Label>
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">$</span>
            </div>
            <Input
              type="number"
              name="cost"
              id="cost"
              value={cost}
              onChange={(e) => setCost(e.target.value)}
            />
          </div>
        </FormGroup>

        {/* Incident Evidence */}
        <FormGroup>
          <Label for="incidentEvidence">Incident Evidence</Label>
          <Input
            className="mb-2"
            type="file"
            name="incidentEvidence"
            id="incidentEvidence"
            accept=".pdf,.png,.jpeg"
            multiple
            onChange={(e) => setIncidentEvidence(Array.from(e.target.files))}
          />
          {incidentEvidence && (
            <div>
              {incidentEvidence.map((file, index) => (
                <p key={index} className="text-success mb-2">
                  {file.name}
                </p>
              ))}
            </div>
          )}
        </FormGroup>

        <div className="form-section-separator-container">
          <div className="form-section-separator" />
          <h5 className="form-section-separator-title">Corrective Action</h5>
          <div className="form-section-separator" />
        </div>

        {/* Corrective Action Type */}
        <FormGroup>
          <Label for="correctiveActionType">Corrective Action Type</Label>
          <Input
            type="select"
            name="correctiveActionType"
            id="correctiveActionType"
            value={correctiveActionType}
            onChange={(e) => setCorrectiveActionType(e.target.value)}
          >
            <option value="">Select Corrective Action Type</option>
            <option value="remove">Remove</option>
            <option value="keep_no_modification">Keep - No Modification</option>
            <option value="keep_with_modification">
              Keep - With Modification
            </option>
          </Input>
        </FormGroup>

        {/* Corrective Action Description */}
        <FormGroup>
          <Label for="correctiveActionDescription">
            Corrective Action Description
          </Label>
          <Input
            type="textarea"
            name="correctiveActionDescription"
            id="correctiveActionDescription"
            value={correctiveActionDescription}
            onChange={(e) => setCorrectiveActionDescription(e.target.value)}
          />
        </FormGroup>

        {/* Corrective Action Date */}
        <FormGroup>
          <Label for="correctiveActionDate">Corrective Action Date</Label>
          <Input
            type="date"
            name="correctiveActionDate"
            id="correctiveActionDate"
            value={correctiveActionDate}
            onChange={(e) => setCorrectiveActionDate(e.target.value)}
          />
        </FormGroup>

        {/* Corrective Action Evidence */}
        <FormGroup>
          <Label for="correctiveActionEvidence">
            Corrective Action Evidence
          </Label>
          <Input
            className="mb-2"
            type="file"
            name="correctiveActionEvidence"
            id="correctiveActionEvidence"
            accept=".pdf,.png,.jpeg"
            multiple
            onChange={(e) =>
              setCorrectiveActionEvidence(Array.from(e.target.files))
            }
          />
          {correctiveActionEvidence && (
            <div>
              {correctiveActionEvidence.map((file, index) => (
                <p key={index} className="text-success mb-2">
                  {file.name}
                </p>
              ))}
            </div>
          )}
        </FormGroup>

        {/* Status */}
        <FormGroup>
          <Label for="status">Status</Label>
          <Input
            type="select"
            name="status"
            id="status"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          >
            <option value="draft">Draft</option>
            <option value="open">Open</option>
            <option value="closed">Closed</option>
          </Input>
        </FormGroup>

        <Button type="submit" className="incident-form-submit submit">
          Submit Incident
        </Button>
      </form>

      {/* Spinner overlay on submit */}
      {isLoading && (
        <div className="spinner-loading-background">
          <Spinner className="spinner-loading" />
        </div>
      )}

      {/* Submit Sucess */}
      <Modal isOpen={submitted}>
        <ModalHeader
          className="modal-header text-white"
          style={{ backgroundColor: '#00ad8e' }}
        >
          Incident Form
        </ModalHeader>
        <ModalBody className="modal-body">
          <div>Your form has been submitted successfully!</div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default IncidentsForm;
