import { Router, Route, Switch } from 'react-router-dom';
import { Container } from 'reactstrap';

import { useAuth0 } from '@auth0/auth0-react';

import history from './utils/history';

import NavBar from './components/NavBar';
import Home from './views/Home';
import SelectProject from './views/SelectProject';
import Reports from './views/Reports';
import Overview from './views/Overview';

import Loading from './components/Loading';

import './App.css';

import initFontAwesome from './utils/initFontAwesome';
import Incidents from './views/Incidents';
initFontAwesome();

const App = () => {
  const { isLoading, error, user } = useAuth0();
  let free_tier;

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <Loading />;
  } else {
    if (user) {
      free_tier = user['https://www.qualomate.com/app_metadata'].free_tier;
    }
  }

  return (
    <Router history={history}>
      <div id="app" className="d-flex flex-column h-100">
        <NavBar free_tier={free_tier} />
        <Container className="flex-grow-1 main-content">
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/select-project" component={SelectProject} />
            <Route
              path="/reports"
              render={(props) => <Reports {...props} free_tier={free_tier} />}
            />
            {!free_tier && <Route path="/incidents" component={Incidents} />}
            {/* {!free_tier && <Route path="/overview" component={Overview} />} */}
          </Switch>
        </Container>
      </div>
    </Router>
  );
};

export default App;
