import { useEffect, useState } from 'react';

const Hero = () => {
  const [content, setContent] = useState('');

  useEffect(() => {
    // Function to update content based on screen width
    const updateContent = () => {
      if (window.innerWidth < 768) {
        setContent(
          'Click the menu button in the top right corner, select "Log in" to get started filling out Reports for your projects.'
        );
      } else {
        setContent(
          'Click "Log in" to get started filling out Reports for your projects.'
        );
      }
    };

    // Initial update
    updateContent();

    // Event listener to update content on window resize
    window.addEventListener('resize', updateContent);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateContent);
    };
  }, []); // Empty dependency array ensures the effect runs only once

  return (
    <div className="text-center hero my-5 px-3">
      <h1 className="mb-4">Welcome to Qualomate Mobile!</h1>
      <p className="lead">{content}</p>
    </div>
  );
};

export default Hero;
