import { useState } from 'react';
import {
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert,
  Spinner,
} from 'reactstrap';
import { TagsInput } from 'react-tag-input-component';

import { useAuth0 } from '@auth0/auth0-react';
import { getConfig } from '../config';
import 'bootstrap/dist/css/bootstrap.min.css';

import './SubmitReport.css';


async function createPrintableHTML (htmlString) {
  return await new Promise((resolve, reject) => {
    // Get the template_id from the form document
    const templateId = htmlString.querySelector('meta[template_id]').getAttribute("template_id");
    let db;
    let values;
    // Open IndexedDB database
    var request = window.indexedDB.open("qualomateDatabase", 1);
    request.onerror = function (event) { 
      console.error("IndexedDB error opening:", event.target.error);
      reject(event.target.error) ;
    };
    request.onsuccess = function (event) {
      db = event.target.result;
      // Get the form data from this templates object store in the IndexedDB
      var transaction = db.transaction(["qualomateStore"], "readonly");
      var objectStore = transaction.objectStore("qualomateStore");
      var request2 = objectStore.get(templateId);

      request2.onerror = function (event) {
        console.error("IndexedDB error querying:", event.target.error);
        reject(event.target.error) ;
      };

      request2.onsuccess = function (event) {
        values = event.target.result;

        const inputElements = htmlString.getElementsByTagName('*');
        for (let inputElement of inputElements) {
          const id = inputElement.getAttribute('id');
          if (id && values.hasOwnProperty(id)) {
            if (inputElement.tagName === 'INPUT') {
              if ((inputElement.type === 'text') || (inputElement.type === 'datetime-local')) {
                inputElement.setAttribute('value', values[id]);
              } else if (inputElement.type === 'number') {
                inputElement.setAttribute('value', Number(values[id]));
              } else if (inputElement.type === 'checkbox') {
                var isItChecked = values[id];
                if (isItChecked) {
                  inputElement.setAttribute('checked', 'checked');
                } else {
                  inputElement.removeAttribute('checked');
                }
              }
            } else if (inputElement.tagName === 'TEXTAREA') {
              inputElement.innerHTML = values[id];
            } else if (inputElement.tagName === 'TABLE') {
              inputElement.outerHTML = values[id];
            } else if (inputElement.tagName === 'SELECT') {
              for (let i = 0; i < inputElement.options.length; i++) {
                inputElement.options[i].removeAttribute('selected');
              }
              inputElement.options[inputElement.selectedIndex].setAttribute(
                'selected',
                'selected'
              );
            }
          }
        }
        resolve(htmlString)
      }
    };
  })
};

const SubmitReport = ({ iframeRef, template_name, free_tier }) => {
  const { apiOrigin = 'https://app.qualomate.com', audience } = getConfig();
  // const { apiOrigin = 'http://127.0.0.1:8050', audience } = getConfig();

  const { user, getAccessTokenSilently } = useAuth0();

  const [isLoading, setIsLoading] = useState(false);

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [emails, setEmails] = useState([user.email]);
  const [apiResponse, setApiResponse] = useState(null);
  const [alertVisible, setAlertVisible] = useState(false);

  const sendReport = async (emails) => {
    if (iframeRef.current) {
      const srcdocContent = iframeRef.current.contentDocument.documentElement;
      let printableHTML = await createPrintableHTML(srcdocContent);

      try {
        setIsLoading(true);

        const token = await getAccessTokenSilently();

        const response = await fetch(`${apiOrigin}/api/report-templates`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            project_id: localStorage.getItem('projectId'),
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            htmlString: printableHTML.outerHTML,
            emailList: emails,
            templateName: template_name.template_name,
            processDocument: false,
            freeTier: free_tier,
          }),
        });

        const confirmation = await response.json();

        // Update state based on API response
        setApiResponse(confirmation);
        // Show the alert
        setAlertVisible(true);
      } catch (error) {
        // Handle errors
        console.error('Error fetching data:', error);
        setAlertVisible(true);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    await sendReport(emails);
  };

  let status_code;
  if (apiResponse) {
    status_code = apiResponse.status_code;
  } else {
    status_code = 500;
  }

  return (
    <div>
      <Row className="submit-report-container">
        <Button
          key="openSubmitModal"
          className="report-form-submit"
          onClick={toggle}
          disabled={!iframeRef.current}
        >
          Submit Report
        </Button>
      </Row>

      <Modal isOpen={modal} toggle={toggle}>
        <form onSubmit={handleSubmit}>
          <ModalHeader toggle={toggle}>Submit Report</ModalHeader>
          <ModalBody>
            <TagsInput
              value={emails}
              onChange={setEmails}
              name="emails"
              placeHolder="Enter emails for notification"
            />
          </ModalBody>
          <ModalFooter>
            <Button key="submitReport" color="primary" type="submit">
              Submit
            </Button>
            <Alert
              color={status_code === 200 ? 'success' : 'danger'}
              isOpen={alertVisible}
              toggle={() => {
                setAlertVisible(false);
              }}
            >
              {apiResponse
                ? apiResponse.body
                : 'Error: Unable to submit Report.'}
            </Alert>
          </ModalFooter>
        </form>
      </Modal>

      {isLoading && (
        <div className="spinner-loading-background">
          <Spinner className="spinner-loading" />
        </div>
      )}
    </div>
  );
};

export default SubmitReport;
