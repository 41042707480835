import { useEffect, useRef } from 'react';
import { Viewer } from 'resium';
import {
  Cartesian3,
  Color,
  Ion,
  ProviderViewModel,
  buildModuleUrl,
  UrlTemplateImageryProvider,
  MapboxStyleImageryProvider,
  GeoJsonDataSource,
  defined,
  ScreenSpaceEventType,
  ColorMaterialProperty,
} from 'cesium';

import * as turf from '@turf/turf';

Ion.defaultAccessToken = null;

const Cesium3DMap = ({ lotData: { lot_map_colors, lot_maps } }) => {
  const accessToken = Ion.defaultAccessToken;

  const viewerRef = useRef(null);

  console.log(viewerRef);

  const toRadians = (degrees) => degrees * (Math.PI / 180);

  useEffect(() => {
    const loadGeoJsonData = async () => {
      let imageryViewModels = [];

      imageryViewModels.push(
        new ProviderViewModel({
          name: 'Map',
          iconUrl: buildModuleUrl(
            'Widgets/Images/ImageryProviders/openStreetMap.png'
          ),
          tooltip:
            'OpenStreetMap (OSM) is a collaborative project to create a free editable map of the world.\nhttp://www.openstreetmap.org',
          creationFunction: function () {
            return new UrlTemplateImageryProvider({
              url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
              subdomains: 'abc',
              minimumLevel: 0,
              maximumLevel: 19,
            });
          },
        })
      );

      imageryViewModels.push(
        new ProviderViewModel({
          name: 'Satellite',
          iconUrl: buildModuleUrl(
            'Widgets/Images/ImageryProviders/mapboxSatellite.png'
          ),
          tooltip:
            'OpenStreetMap (OSM) is a collaborative project to create a free editable map of the world.\nhttp://www.openstreetmap.org',
          creationFunction: function () {
            return new MapboxStyleImageryProvider({
              styleId: 'satellite-v9',
              accessToken,
            });
          },
        })
      );

      const viewer = viewerRef.current.cesiumElement; // Reference to Resium Viewer

      // Remove the Terrain section of the baseLayerPicker
      viewer.baseLayerPicker.viewModel.terrainProviderViewModels.removeAll();

      const centroids = [];

      // Loop through lot maps data
      lot_maps.forEach(async (layer, index) => {
        if (layer[0] != null) {
          // Calculate the centroid of the geojson file and add it to the centroids array
          const layerCentroid = turf.centroid(layer[0]);
          centroids.push(layerCentroid.geometry.coordinates);

          // Load each geojson file
          const dataSource = await GeoJsonDataSource.load(layer[0]);

          // Define the thickness of the layer and space between layers
          const layer_thickness = 1;
          const layer_gap = 10;
          const bottom_of_layer = index * (layer_gap + layer_thickness); // gap between layers + thickness of layer

          // For each polygon in the geojson, set its properties
          dataSource.entities.values.forEach((entity) => {
            // Set the height above ground and thickness of the layer
            entity.polygon.height = bottom_of_layer;
            entity.polygon.extrudedHeight = bottom_of_layer + layer_thickness; // top of layer

            // Set the color of the individual polygon based on lot status
            const lot_status = lot_map_colors[entity.id];
            const colors = {
              'Closed Out': Color.fromCssColorString('#28282B'),
              Incident: Color.fromCssColorString('#FF0000'),
              Passed: Color.fromCssColorString('#00AD8E'),
              'Not Started': Color.fromCssColorString('#bfbfbf'),
              'In Progress': Color.fromCssColorString('#FFD500'),
              'Closed Out w/ Incident': Color.fromCssColorString('#0000FF'),
            };
            entity.polygon.material = colors[lot_status];
            entity.polygon.outline = true;
            entity.polygon.outlineColor = Color.BLACK;
          });

          // Add the data source to the viewer
          viewer.dataSources.add(dataSource);
        }
      });

      // HTML overlay for showing feature name on mouseover
      const nameOverlay = document.createElement('div');
      viewer.container.appendChild(nameOverlay);
      nameOverlay.className = 'backdrop';
      nameOverlay.style.display = 'none';
      nameOverlay.style.position = 'absolute';
      nameOverlay.style.bottom = '0';
      nameOverlay.style.left = '0';
      nameOverlay.style['pointer-events'] = 'none';
      nameOverlay.style.padding = '4px';
      nameOverlay.style.backgroundColor = 'white';
      nameOverlay.style.color = 'black';

      // Update the 'nameOverlay' for the given picked feature, at the given (screen) position.
      function updateNameOverlay(pickedFeature, position) {
        if (!defined(pickedFeature)) {
          nameOverlay.style.display = 'none';
          return;
        }
        // A feature was picked, so show its overlay content
        nameOverlay.style.display = 'block';
        nameOverlay.style.bottom = `${
          viewer.canvas.clientHeight -
          position.y +
          viewer.canvas.clientHeight * 0.2
        }px`;
        nameOverlay.style.left = `${position.x}px`;
        let name = pickedFeature._id.split('$');
        name = name[1] + ' ' + name[2] + ' ' + name[3];
        nameOverlay.textContent = name;
      }

      // Information about the currently highlighted feature
      let highlighted = {
        feature: undefined,
        originalColor: new Color(),
      };

      // Information about the currently selected feature
      let selected = {
        feature: undefined,
        originalColor: new Color(),
      };

      // Information about the features that are part of the same lot group as the currently selected feature
      let secondarySelected = {
        features: [],
        originalColors: [],
      };

      // Color a feature brown on hover.
      viewer.screenSpaceEventHandler.setInputAction(function onMouseMove(
        movement
      ) {
        // Pick a new feature
        let pickedFeature = viewer.scene.pick(movement.endPosition);
        let pickedColor;

        if (!defined(pickedFeature)) {
          updateNameOverlay(pickedFeature, movement.endPosition);
          document.body.style.cursor = 'default';
          return;
        } else {
          pickedFeature = pickedFeature.id;
          updateNameOverlay(pickedFeature, movement.endPosition);
          document.body.style.cursor = 'pointer';
        }
      },
      ScreenSpaceEventType.MOUSE_MOVE);

      // Get default left click handler for when a feature is not picked on left click
      const clickHandler = viewer.screenSpaceEventHandler.getInputAction(
        ScreenSpaceEventType.LEFT_CLICK
      );

      // Color a lot purple on selection and color the other lots medium purple that belong to the same lot group.
      viewer.screenSpaceEventHandler.setInputAction(function onLeftClick(
        movement
      ) {
        // If a feature was previously selected, undo the selection highlight
        if (defined(selected.feature)) {
          selected.feature.polygon.material = new ColorMaterialProperty(
            selected.originalColor
          );
          selected.feature = undefined;
          selected.originalColor = new Color();

          // Loop through saved secondary selected features (currently medium purple) and reset them to their original colors
          for (let i = 0; i < secondarySelected.features.length; i++) {
            secondarySelected.features[i].polygon.material =
              secondarySelected.originalColors[i];
          }
          // Clear existing features and colors from secondarySelected (medium purple)
          secondarySelected.features.length = 0;
          secondarySelected.originalColors.length = 0;
        }

        // Pick a new feature (lot)
        let clickedFeature = viewer.scene.pick(movement.position);
        let clickedColor;
        if (!defined(clickedFeature)) {
          clickHandler(movement);
          // Create a event with no data so the Dash app can listen for it and reset the package list
          const nothingClickedEvent = new CustomEvent('lotClicked', {
            detail: { data: null },
            bubbles: true,
          });
          document.dispatchEvent(nothingClickedEvent);
          return;
        } else {
          clickedFeature = clickedFeature.id;
          clickedColor = clickedFeature.polygon.material.getValue().color;
        }

        const clickedFeatureId = clickedFeature._id;
        // Create an event so the Dash app can listen for it when a lot is clicked
        const lotClickedEvent = new CustomEvent('lotClicked', {
          detail: { data: clickedFeatureId },
          bubbles: true,
        });
        document.dispatchEvent(lotClickedEvent);

        // Find the lot group that the picked Lot belongs to
        // const lot_group = lot_groups[clickedFeatureId];

        // // Get the other lots that are part of the lot group, save their original color and change their color to medium purple
        // for (const lot_id of lot_group) {
        //   for (const _layer of viewer.dataSources._dataSources) {
        //     for (const lot_entity of _layer._entityCollection._entities
        //       ._array) {
        //       if (lot_entity._id === lot_id) {
        //         secondarySelected.features.push(lot_entity);
        //         secondarySelected.originalColors.push(
        //           lot_entity.polygon.material
        //         );
        //         lot_entity.polygon.material = new ColorMaterialProperty(
        //           Color.MEDIUMPURPLE
        //         );
        //       }
        //     }
        //   }
        // }

        // Select the feature if it's not already selected
        if (selected.feature === clickedFeature) {
          return;
        }
        selected.feature = clickedFeature;
        if (clickedFeature === highlighted.feature) {
          Color.clone(highlighted.originalColor, selected.originalColor);
          highlighted.feature = undefined;
        } else {
          Color.clone(clickedColor, selected.originalColor);
        }

        // Highlight newly selected feature
        clickedFeature.polygon.material = new ColorMaterialProperty(
          Color.PURPLE
        );
      },
      ScreenSpaceEventType.LEFT_CLICK);

      // Get the center of the lot maps by calculating the center of the centroids of the layers
      let lot_map_center = turf.center(turf.points(centroids));
      lot_map_center = lot_map_center.geometry.coordinates;

      // Fly to the center of the lot maps
      const target_location = Cartesian3.fromDegrees(
        lot_map_center[0],
        lot_map_center[1],
        300
      );
      viewer.camera.setView({
        destination: target_location,
        orientation: {
          heading: toRadians(0),
          pitch: toRadians(-25),
          roll: 0,
        },
      });
    };
    loadGeoJsonData();
  }, [lot_maps, lot_map_colors, accessToken]);

  return <Viewer ref={viewerRef} style={{ width: '100%', height: '85vh' }} />;
};

export default Cesium3DMap;
