import {length, nearestPointOnLine, point} from '@turf/turf'

function calculateStationOffset(alignment, coords, units) {

  const currentLocation = point([coords.longitude, coords.latitude]);
  // const currentLocation = point([-73.57846291245482, 45.49958127887351])

    let unitConverter = 3280.84; // convert km to feet
    if (units === 'metric') {
        unitConverter = 1000; // convert km to m
    }
    const coordOnLine = nearestPointOnLine(alignment, currentLocation, {units: 'kilometers'})
    
    var lineLength = length(alignment, {units: 'kilometers'})*unitConverter;
    lineLength = Math.round(lineLength*100)/100
    
    var offset = coordOnLine.properties.dist*unitConverter.toFixed(2);
    
    var distFromStart = coordOnLine.properties.location*unitConverter;
    distFromStart = Math.round(distFromStart*100)/100

    return [lineLength, offset, distFromStart]
}

export default calculateStationOffset;
