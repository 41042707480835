import { Container } from 'reactstrap';

import Loading from '../components/Loading';
import ButtonStack from '../components/ButtonStack';

import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';

export const ProfileComponent = () => {
  const { user } = useAuth0();
  localStorage.removeItem('projectId');

  return (
    <Container className="mb-5">
        <div>
            <ButtonStack projects={user["https://www.qualomate.com/app_metadata"]} />
        </div>
    </Container>
  );
};

export default withAuthenticationRequired(ProfileComponent, {
  onRedirecting: () => <Loading />,
});
