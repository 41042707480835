import { useState, useEffect, useRef } from 'react';
import { useGeolocated } from 'react-geolocated';

import {
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  ModalHeader,
  ModalBody,
  Modal,
} from 'reactstrap';

import ModalQ from './Modal';
import SubmitReport from '../components/SubmitReport';
import CurrentStation from '../components/CurrentStation';

import './ReportTemplate.css';

const ReportTemplates = ({ jsonData, free_tier }) => {
  const geojson = jsonData.alignment;
  const alignmentFirstStation = jsonData.alignmentFirstStation;

  const [alignment, setAlignment] = useState(geojson);
  const [firstStation, setFirstStation] = useState(alignmentFirstStation);

  const [selectedSubPackage, setSelectedSubPackage] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [dropdownOpen1, setDropdownOpen1] = useState(false);
  const [dropdownOpen2, setDropdownOpen2] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isReportCleared, setIsReportCleared] = useState(false);

  const [iFrameHeight, setIFrameHeight] = useState(0);
  const iframeRef = useRef(null);

  // Get current lat/long
  const { coords, isGeolocationAvailable, isGeolocationEnabled, getPosition } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: true,
      },
      userDecisionTimeout: Infinity,
      watchPosition: false,
    });

  useEffect(() => {
    // Reset selected template when subPackage changes
    setSelectedTemplate(null);
  }, [selectedSubPackage]);

  useEffect(() => {
    let timeoutId;
    if (isReportCleared) {
      timeoutId = setTimeout(() => {
        setIsReportCleared(false);
      }, 2000);
    }
    return () => clearTimeout(timeoutId);
  }, [isReportCleared]);

  const toggleDropdown1 = () => setDropdownOpen1((prevState) => !prevState);
  const toggleDropdown2 = () => setDropdownOpen2((prevState) => !prevState);

  const handleSubPackageChange = (subPackage) => {
    setSelectedSubPackage(subPackage);
  };

  const handleTemplateChange = (template) => {
    setSelectedTemplate(template);
  };

  const handleModalOpen = () => {
    setIsModalOpen((prevIsModalOpen) => !prevIsModalOpen);
  };

  const handleClearConfirm = () => {
    let db;
    var request = window.indexedDB.open("qualomateDatabase", 1);
    request.onerror = function (event) {
      console.error("IndexedDB error:", event.target.error);
    };
    request.onsuccess = function (event) {
      db = event.target.result;
      var transaction = db.transaction(["qualomateStore"], "readwrite");
      var objectStore = transaction.objectStore("qualomateStore");
      
      // Get template id from iframe
      var iframeString = document.getElementById("reportViewer").srcdoc;
      var parsedString = document.createElement('html');
      parsedString.innerHTML = iframeString;
      var templateId = parsedString.querySelector('meta[template_id]').getAttribute("template_id");
      
      var deleteRequest = objectStore.delete(templateId);
      
      deleteRequest.onsuccess = function(event) {
        if (iframeRef.current) {
          iframeRef.current.contentWindow.location.reload();
        }
        handleModalOpen();
        setIsReportCleared(true);
      }
    deleteRequest.onerror = function(event) {
      console.error("IndexedDB error unable to clear form data:", event.target.error);
    }
    };
  };

  // On loading selected template determine the initial height of the iframe document
  const onLoad = () => {
    setIFrameHeight(
      iframeRef.current.contentWindow.document.documentElement.scrollHeight
    );
  };

  delete jsonData['alignment'];
  delete jsonData['alignmentFirstStation'];

  return (
    <>
      <Row className="report-control">
        <Col className="report-control-btn-container">
          <Dropdown isOpen={dropdownOpen1} toggle={toggleDropdown1}>
            <DropdownToggle caret className="subpackage-dropdown-toggle">
              {selectedSubPackage ? selectedSubPackage : 'Select Subpackage'}
            </DropdownToggle>
            <DropdownMenu>
              {Object.keys(jsonData).map((subPackage) => (
                <DropdownItem
                  key={subPackage}
                  onClick={() => handleSubPackageChange(subPackage)}
                >
                  {subPackage}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        </Col>

        <Col className="report-control-btn-container">
          <Dropdown isOpen={dropdownOpen2} toggle={toggleDropdown2}>
            <DropdownToggle
              caret
              className="template-dropdown-toggle"
              disabled={!selectedSubPackage}
            >
              {selectedTemplate
                ? selectedTemplate.template_name
                : 'Select Template'}
            </DropdownToggle>
            {selectedSubPackage && (
              <DropdownMenu>
                {jsonData[selectedSubPackage].map((_template) => (
                  <DropdownItem
                    key={_template.template_name}
                    onClick={() => handleTemplateChange(_template)}
                  >
                    {_template.template_name}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            )}
          </Dropdown>
        </Col>

        <Col className="report-control-btn-container">
          <Button
            color="danger"
            className="clear-report-btn"
            onClick={handleModalOpen}
            disabled={!selectedTemplate}
          >
            Clear Report &#10005;
          </Button>
        </Col>
        {!free_tier && (
          <Col id="current-station-container">
          <Button
            onClick={() => {
              getPosition();
            }}
            className="update-location-btn"
          >
            &#8635;
          </Button>

          <CurrentStation
            alignment={alignment}
            alignmentFirstStation={firstStation}
            units={'metric'}
            coords={coords}
            isGeolocationAvailable={isGeolocationAvailable}
            isGeolocationEnabled={isGeolocationEnabled}
            id="current-station-text"
          />
        </Col>
        )}
        
      </Row>

      <Row id="report-template-container">
        {selectedTemplate && (
          <iframe
            id="reportViewer"
            ref={iframeRef}
            title="Selected Template"
            srcDoc={selectedTemplate.template.content}
            style={{
              width: '100%',
              height: iFrameHeight,
              border: '1px solid #ddd',
            }}
            onLoad={onLoad}
          ></iframe>
        )}
      </Row>

      {selectedTemplate && (
        <SubmitReport iframeRef={iframeRef} template_name={selectedTemplate} free_tier={free_tier}/>
      )}

      <ModalQ
        isOpen={isModalOpen}
        toggle={handleModalOpen}
        title="Clear Report"
        body="Are you sure you want to clear the report?"
        onConfirm={handleClearConfirm}
      />

      <Modal isOpen={isReportCleared}>
        <ModalHeader
          className="modal-header text-white"
          style={{ backgroundColor: '#00ad8e' }}
        >
          Report Cleared
        </ModalHeader>
        <ModalBody className="modal-body">
          <div>Your report has been cleared successfully!</div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ReportTemplates;
