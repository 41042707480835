import { Link } from 'react-router-dom';

import './ButtonStack.css';

const ButtonStack = ({ projects }) => {

  const handleButtonClick = (projectId) => {
    // Save projectId to localStorage
    localStorage.setItem('projectId', projectId);
  };

  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      {Object.entries(projects.project_id).map(([key, value]) => (
        <Link
          to={`/reports/${value}`}
          key={key}
          style={{ textDecoration: 'none' }}
        >
          <button key={key} className="select-project-btn" onClick={() => handleButtonClick(value)}>
            {value}
          </button>
        </Link>
      ))}
    </div>
  );
};

export default ButtonStack;
