import Hero from '../components/Hero';
// import Content from "../components/Content";
import Footer from '../components/Footer';

const Home = () => (
  <>
    <Hero />
    <Footer />
    {/* <Content /> */}
  </>
);

export default Home;
